/* Mixins */
.device-method-component .method-wrapper {
  display: inline-block;
  width: 350px;
  vertical-align: top;
}
.device-method-component .method-wrapper .method-card-item {
  height: 150px;
  margin: 20px 20px 0 0;
  overflow: auto;
}